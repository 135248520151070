import React from 'react';

const Image = ({ src, alt, ...props }) => {
    const appUrl = process.env.REACT_APP_APP_URL || '';

    return (
        <img
            src={`${appUrl}${src}`}
            alt={alt}
            {...props}
        />
    );
};

export default Image;
