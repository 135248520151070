import React, { useState, useEffect, useRef } from "react";
import countries from "../constants/countries";
import ReactFlagsSelect from "react-flags-select";
const PhoneInput = ({ onChange, nationality }) => {
  const [selectedCountry, setSelectedCountry] = useState(nationality ?? "US");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState(countries["US"].code);
  const [phoneNumberWithCode, setPhoneNumberWithCode] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    setPhoneNumberWithCode(`${countryCode}${phoneNumber}`);
  }, [countryCode, phoneNumber]);

  useEffect(() => {
    if (onChange) {
      onChange(phoneNumberWithCode);
    }

    // eslint-disable-next-line
  }, [phoneNumberWithCode]);

  useEffect(() => { 
    setSelectedCountry(nationality);
    setCountryCode(countries[nationality]?.code ?? '+1');
    // eslint-disable-next-line
  }, [nationality]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setCountryCode(countries[country].code);
  };

  const handlePhoneNumberChange = (event) => {
    const phone = event.target.value.replace(/\D/g, "");
    setPhoneNumber(phone);
    setPhoneNumberWithCode(`${countryCode}${phone}`);
  };

  return (
    <div className="chat-flex chat-items-center" ref={dropdownRef}>
      <ReactFlagsSelect
        className={`chat-w-[110px] chat-rounded-[8px] chat-flex chat-items-center chat-pt-[5px] chat-h-[52px] chat-appearance-none chat-leading-tight chat-text-black chat-bg-white chat-cursor-pointer`}
        selectButtonClassName="!chat-border-none"
        fullWidth={true}
        selectedSize={16}
        optionsSize={16}
        placeholder="Country"
        selected={selectedCountry}
        onSelect={(code) => handleCountryChange(code)}
        searchable
        countries={Object.keys(countries)}
        customLabels={Object.fromEntries(
          Object.entries(countries).map(([code, { code: phoneCode, name }]) => [
            code,
            { primary: name, secondary: phoneCode },
          ])
        )}
        showSelectedLabel={false}
      />
      <input
        type="text"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        className="chat-ml-2 chat-h-[52px] chat-shadow chat-appearance-none chat-border chat-rounded-[8px] chat-flex-1 chat-py-2 chat-px-3 chat-text-gray-700 chat-leading-tight focus:chat-outline-none focus:chat-shadow-outline"
        placeholder={countries[selectedCountry]?.placeholder}
      />
    </div>
  );
};

export default PhoneInput;
