import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

document.addEventListener('DOMContentLoaded', () => {
    const widget = document.getElementById('grs-chat-widget');
    if (widget) {
        const root = ReactDOM.createRoot(widget);
        root.render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
    }
});

reportWebVitals();
