const countries = {
  AF: {
    name: "Afghanistan",
    code: "+93",
    flag: "🇦🇫",
    placeholder: "70 123 4567",
  },
  AL: { name: "Albania", code: "+355", flag: "🇦🇱", placeholder: "69 123 4567" },
  DZ: {
    name: "Algeria",
    code: "+213",
    flag: "🇩🇿",
    placeholder: "661 23 45 67",
  },
  AS: {
    name: "American Samoa",
    code: "+1",
    flag: "🇦🇸",
    placeholder: "733 1234",
  },
  AD: { name: "Andorra", code: "+376", flag: "🇦🇩", placeholder: "123 456" },
  AO: { name: "Angola", code: "+244", flag: "🇦🇴", placeholder: "923 123 456" },
  AI: { name: "Anguilla", code: "+1", flag: "🇦🇮", placeholder: "235 1234" },
  AQ: { name: "Antarctica", code: "", flag: "🇦🇶", placeholder: "" },
  AG: {
    name: "Antigua and Barbuda",
    code: "+1",
    flag: "🇦🇬",
    placeholder: "555 1234",
  },
  AR: {
    name: "Argentina",
    code: "+54",
    flag: "🇦🇷",
    placeholder: "11 1234 5678",
  },
  AM: { name: "Armenia", code: "+374", flag: "🇦🇲", placeholder: "77 123 456" },
  AW: { name: "Aruba", code: "+297", flag: "🇦🇼", placeholder: "56 1234" },
  AU: {
    name: "Australia",
    code: "+61",
    flag: "🇦🇺",
    placeholder: "412 345 678",
  },
  AT: { name: "Austria", code: "+43", flag: "🇦🇹", placeholder: "664 123456" },
  AZ: {
    name: "Azerbaijan",
    code: "+994",
    flag: "🇦🇿",
    placeholder: "50 123 45 67",
  },
  BS: { name: "Bahamas", code: "+1", flag: "🇧🇸", placeholder: "359 1234" },
  BH: { name: "Bahrain", code: "+973", flag: "🇧🇭", placeholder: "3600 1234" },
  BD: {
    name: "Bangladesh",
    code: "+880",
    flag: "🇧🇩",
    placeholder: "15 1234 5678",
  },
  BB: { name: "Barbados", code: "+1", flag: "🇧🇧", placeholder: "250 1234" },
  BY: { name: "Belarus", code: "+375", flag: "🇧🇾", placeholder: "29 1234567" },
  BE: { name: "Belgium", code: "+32", flag: "🇧🇪", placeholder: "47 123 45 67" },
  BZ: { name: "Belize", code: "+501", flag: "🇧🇿", placeholder: "622 1234" },
  BJ: { name: "Benin", code: "+229", flag: "🇧🇯", placeholder: "90 12 34 56" },
  BM: { name: "Bermuda", code: "+1", flag: "🇧🇲", placeholder: "555 1234" },
  BT: { name: "Bhutan", code: "+975", flag: "🇧🇹", placeholder: "17 123 456" },
  BO: { name: "Bolivia", code: "+591", flag: "🇧🇴", placeholder: "71234567" },
  BQ: {
    name: "Bonaire, Saint Eustatius and Saba",
    code: "+599",
    flag: "🇧🇶",
    placeholder: "318 1234",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    code: "+387",
    flag: "🇧🇦",
    placeholder: "61 123 456",
  },
  BW: { name: "Botswana", code: "+267", flag: "🇧🇼", placeholder: "71 123 456" },
  BR: { name: "Brazil", code: "+55", flag: "🇧🇷", placeholder: "11 91234 5678" },
  IO: {
    name: "British Indian Ocean Territory",
    code: "+246",
    flag: "🇮🇴",
    placeholder: "123 4567",
  },
  VG: {
    name: "British Virgin Islands",
    code: "+1",
    flag: "🇻🇬",
    placeholder: "300 1234",
  },
  BN: { name: "Brunei", code: "+673", flag: "🇧🇳", placeholder: "712 3456" },
  BG: {
    name: "Bulgaria",
    code: "+359",
    flag: "🇧🇬",
    placeholder: "87 123 4567",
  },
  BF: {
    name: "Burkina Faso",
    code: "+226",
    flag: "🇧🇫",
    placeholder: "70 12 34 56",
  },
  BI: { name: "Burundi", code: "+257", flag: "🇧🇮", placeholder: "79 123 456" },
  KH: { name: "Cambodia", code: "+855", flag: "🇰🇭", placeholder: "92 123 456" },
  CM: {
    name: "Cameroon",
    code: "+237",
    flag: "🇨🇲",
    placeholder: "6 7 12 34 56",
  },
  CA: { name: "Canada", code: "+1", flag: "🇨🇦", placeholder: "204 123 4567" },
  CV: {
    name: "Cape Verde",
    code: "+238",
    flag: "🇨🇻",
    placeholder: "991 12 34",
  },
  KY: {
    name: "Cayman Islands",
    code: "+1",
    flag: "🇰🇾",
    placeholder: "323 1234",
  },
  CF: {
    name: "Central African Republic",
    code: "+236",
    flag: "🇨🇫",
    placeholder: "70 12 34 56",
  },
  TD: { name: "Chad", code: "+235", flag: "🇹🇩", placeholder: "63 01 23 45" },
  CL: { name: "Chile", code: "+56", flag: "🇨🇱", placeholder: "9 1234 5678" },
  CN: { name: "China", code: "+86", flag: "🇨🇳", placeholder: "131 1234 5678" },
  CX: {
    name: "Christmas Island",
    code: "+61",
    flag: "🇨🇽",
    placeholder: "4 123 456",
  },
  CC: {
    name: "Cocos Islands",
    code: "+61",
    flag: "🇨🇨",
    placeholder: "4 123 456",
  },
  CO: {
    name: "Colombia",
    code: "+57",
    flag: "🇨🇴",
    placeholder: "321 123 4567",
  },
  KM: { name: "Comoros", code: "+269", flag: "🇰🇲", placeholder: "321 23 45" },
  CG: {
    name: "Republic of the Congo",
    code: "+242",
    flag: "🇨🇬",
    placeholder: "06 123 4567",
  },
  CK: { name: "Cook Islands", code: "+682", flag: "🇨🇰", placeholder: "71 234" },
  CR: {
    name: "Costa Rica",
    code: "+506",
    flag: "🇨🇷",
    placeholder: "8312 3456",
  },
  HR: { name: "Croatia", code: "+385", flag: "🇭🇷", placeholder: "91 234 5678" },
  CU: { name: "Cuba", code: "+53", flag: "🇨🇺", placeholder: "5 123 4567" },
  CW: { name: "Curacao", code: "+599", flag: "🇨🇼", placeholder: "9 518 1234" },
  CY: { name: "Cyprus", code: "+357", flag: "🇨🇾", placeholder: "96 123456" },
  CZ: {
    name: "Czech Republic",
    code: "+420",
    flag: "🇨🇿",
    placeholder: "601 123 456",
  },
  CD: {
    name: "Democratic Republic of the Congo",
    code: "+243",
    flag: "🇨🇩",
    placeholder: "99 123 4567",
  },
  DK: { name: "Denmark", code: "+45", flag: "🇩🇰", placeholder: "12 34 56 78" },
  DJ: { name: "Djibouti", code: "+253", flag: "🇩🇯", placeholder: "77 123 456" },
  DM: { name: "Dominica", code: "+1", flag: "🇩🇲", placeholder: "225 1234" },
  DO: {
    name: "Dominican Republic",
    code: "+1-809",
    flag: "🇩🇴",
    placeholder: "212 1234",
  },
  EC: { name: "Ecuador", code: "+593", flag: "🇪🇨", placeholder: "99 123 4567" },
  EG: { name: "Egypt", code: "+20", flag: "🇪🇬", placeholder: "10 123 4567" },
  SV: {
    name: "El Salvador",
    code: "+503",
    flag: "🇸🇻",
    placeholder: "7012 3456",
  },
  GQ: {
    name: "Equatorial Guinea",
    code: "+240",
    flag: "🇬🇶",
    placeholder: "222 123 456",
  },
  ER: { name: "Eritrea", code: "+291", flag: "🇪🇷", placeholder: "7 123 456" },
  EE: { name: "Estonia", code: "+372", flag: "🇪🇪", placeholder: "5123 4567" },
  SZ: { name: "Eswatini", code: "+268", flag: "🇸🇿", placeholder: "7612 3456" },
  ET: {
    name: "Ethiopia",
    code: "+251",
    flag: "🇪🇹",
    placeholder: "911 23 45 67",
  },
  FK: {
    name: "Falkland Islands",
    code: "+500",
    flag: "🇫🇰",
    placeholder: "51234",
  },
  FO: {
    name: "Faroe Islands",
    code: "+298",
    flag: "🇫🇴",
    placeholder: "211234",
  },
  FJ: { name: "Fiji", code: "+679", flag: "🇫🇯", placeholder: "701 2345" },
  FI: { name: "Finland", code: "+358", flag: "🇫🇮", placeholder: "41 2345678" },
  FR: { name: "France", code: "+33", flag: "🇫🇷", placeholder: "6 12 34 56 78" },
  GF: {
    name: "French Guiana",
    code: "+594",
    flag: "🇬🇫",
    placeholder: "694 20 12 34",
  },
  PF: {
    name: "French Polynesia",
    code: "+689",
    flag: "🇵🇫",
    placeholder: "87 12 34 56",
  },
  TF: {
    name: "French Southern Territories",
    code: "",
    flag: "🇹🇫",
    placeholder: "",
  },
  GA: { name: "Gabon", code: "+241", flag: "🇬🇦", placeholder: "06 123 456" },
  GM: { name: "Gambia", code: "+220", flag: "🇬🇲", placeholder: "701 2345" },
  GE: {
    name: "Georgia",
    code: "+995",
    flag: "🇬🇪",
    placeholder: "555 12 34 56",
  },
  DE: { name: "Germany", code: "+49", flag: "🇩🇪", placeholder: "151 12345678" },
  GH: { name: "Ghana", code: "+233", flag: "🇬🇭", placeholder: "23 123 4567" },
  GI: { name: "Gibraltar", code: "+350", flag: "🇬🇮", placeholder: "57123456" },
  GR: { name: "Greece", code: "+30", flag: "🇬🇷", placeholder: "691 234 5678" },
  GL: { name: "Greenland", code: "+299", flag: "🇬🇱", placeholder: "22 12 34" },
  GD: { name: "Grenada", code: "+1", flag: "🇬🇩", placeholder: "403 1234" },
  GP: {
    name: "Guadeloupe",
    code: "+590",
    flag: "🇬🇵",
    placeholder: "690 12 34 56",
  },
  GU: { name: "Guam", code: "+1", flag: "🇬🇺", placeholder: "300 1234" },
  GT: { name: "Guatemala", code: "+502", flag: "🇬🇹", placeholder: "5123 4567" },
  GG: { name: "Guernsey", code: "+44", flag: "🇬🇬", placeholder: "7781 123456" },
  GN: { name: "Guinea", code: "+224", flag: "🇬🇳", placeholder: "601 12 34 56" },
  GW: {
    name: "Guinea-Bissau",
    code: "+245",
    flag: "🇬🇼",
    placeholder: "955 012 345",
  },
  GY: { name: "Guyana", code: "+592", flag: "🇬🇾", placeholder: "609 1234" },
  HT: { name: "Haiti", code: "+509", flag: "🇭🇹", placeholder: "34 12 3456" },
  HN: { name: "Honduras", code: "+504", flag: "🇭🇳", placeholder: "9123 4567" },
  HK: { name: "Hong Kong", code: "+852", flag: "🇭🇰", placeholder: "5123 4567" },
  HU: { name: "Hungary", code: "+36", flag: "🇭🇺", placeholder: "20 123 4567" },
  IS: { name: "Iceland", code: "+354", flag: "🇮🇸", placeholder: "611 1234" },
  IN: { name: "India", code: "+91", flag: "🇮🇳", placeholder: "91234 56789" },
  ID: {
    name: "Indonesia",
    code: "+62",
    flag: "🇮🇩",
    placeholder: "812 3456 7890",
  },
  IR: { name: "Iran", code: "+98", flag: "🇮🇷", placeholder: "912 345 6789" },
  IQ: { name: "Iraq", code: "+964", flag: "🇮🇶", placeholder: "790 123 4567" },
  IE: { name: "Ireland", code: "+353", flag: "🇮🇪", placeholder: "85 123 4567" },
  IM: {
    name: "Isle of Man",
    code: "+44",
    flag: "🇮🇲",
    placeholder: "7924 123456",
  },
  IL: { name: "Israel", code: "+972", flag: "🇮🇱", placeholder: "50 123 4567" },
  IT: { name: "Italy", code: "+39", flag: "🇮🇹", placeholder: "312 345 6789" },
  JM: { name: "Jamaica", code: "+1-876", flag: "🇯🇲", placeholder: "210 1234" },
  JP: { name: "Japan", code: "+81", flag: "🇯🇵", placeholder: "70 1234 5678" },
  JE: { name: "Jersey", code: "+44", flag: "🇯🇪", placeholder: "7700 123456" },
  JO: { name: "Jordan", code: "+962", flag: "🇯🇴", placeholder: "77 123 4567" },
  KZ: {
    name: "Kazakhstan",
    code: "+7",
    flag: "🇰🇿",
    placeholder: "701 123 4567",
  },
  KE: { name: "Kenya", code: "+254", flag: "🇰🇪", placeholder: "712 345678" },
  KI: { name: "Kiribati", code: "+686", flag: "🇰🇮", placeholder: "72012345" },
  XK: { name: "Kosovo", code: "+383", flag: "🇽🇰", placeholder: "43 123 456" },
  KW: { name: "Kuwait", code: "+965", flag: "🇰🇼", placeholder: "500 12345" },
  KG: {
    name: "Kyrgyzstan",
    code: "+996",
    flag: "🇰🇬",
    placeholder: "700 123 456",
  },
  LA: { name: "Laos", code: "+856", flag: "🇱🇦", placeholder: "20 21 234 567" },
  LV: { name: "Latvia", code: "+371", flag: "🇱🇻", placeholder: "21 234 567" },
  LB: { name: "Lebanon", code: "+961", flag: "🇱🇧", placeholder: "3 456 789" },
  LS: { name: "Lesotho", code: "+266", flag: "🇱🇸", placeholder: "5012 3456" },
  LR: { name: "Liberia", code: "+231", flag: "🇱🇷", placeholder: "77 012 3456" },
  LY: { name: "Libya", code: "+218", flag: "🇱🇾", placeholder: "91-1234567" },
  LI: {
    name: "Liechtenstein",
    code: "+423",
    flag: "🇱🇮",
    placeholder: "660 1234",
  },
  LT: { name: "Lithuania", code: "+370", flag: "🇱🇹", placeholder: "612 34567" },
  LU: {
    name: "Luxembourg",
    code: "+352",
    flag: "🇱🇺",
    placeholder: "621 123 456",
  },
  MO: { name: "Macao", code: "+853", flag: "🇲🇴", placeholder: "6612 3456" },
  MK: {
    name: "Macedonia",
    code: "+389",
    flag: "🇲🇰",
    placeholder: "72 345 678",
  },
  MG: {
    name: "Madagascar",
    code: "+261",
    flag: "🇲🇬",
    placeholder: "32 12 345 67",
  },
  MW: { name: "Malawi", code: "+265", flag: "🇲🇼", placeholder: "991 23 45 67" },
  MY: { name: "Malaysia", code: "+60", flag: "🇲🇾", placeholder: "12 345 6789" },
  MV: { name: "Maldives", code: "+960", flag: "🇲🇻", placeholder: "771 2345" },
  ML: { name: "Mali", code: "+223", flag: "🇲🇱", placeholder: "65 12 34 56" },
  MT: { name: "Malta", code: "+356", flag: "🇲🇹", placeholder: "9696 1234" },
  MH: {
    name: "Marshall Islands",
    code: "+692",
    flag: "🇲🇭",
    placeholder: "235 1234",
  },
  MQ: {
    name: "Martinique",
    code: "+596",
    flag: "🇲🇶",
    placeholder: "696 20 12 34",
  },
  MR: {
    name: "Mauritania",
    code: "+222",
    flag: "🇲🇷",
    placeholder: "22 12 34 56",
  },
  MU: { name: "Mauritius", code: "+230", flag: "🇲🇺", placeholder: "5251 2345" },
  YT: {
    name: "Mayotte",
    code: "+262",
    flag: "🇾🇹",
    placeholder: "639 12 34 56",
  },
  MX: {
    name: "Mexico",
    code: "+52",
    flag: "🇲🇽",
    placeholder: "1 234 567 8901",
  },
  FM: { name: "Micronesia", code: "+691", flag: "🇫🇲", placeholder: "350 1234" },
  MD: { name: "Moldova", code: "+373", flag: "🇲🇩", placeholder: "650 12 345" },
  MC: {
    name: "Monaco",
    code: "+377",
    flag: "🇲🇨",
    placeholder: "6 12 34 56 78",
  },
  MN: { name: "Mongolia", code: "+976", flag: "🇲🇳", placeholder: "8812 3456" },
  ME: {
    name: "Montenegro",
    code: "+382",
    flag: "🇲🇪",
    placeholder: "67 123 456",
  },
  MS: { name: "Montserrat", code: "+1", flag: "🇲🇸", placeholder: "492 3456" },
  MA: {
    name: "Morocco",
    code: "+212",
    flag: "🇲🇦",
    placeholder: "612 34 56 78",
  },
  MZ: {
    name: "Mozambique",
    code: "+258",
    flag: "🇲🇿",
    placeholder: "82 123 4567",
  },
  MM: { name: "Myanmar", code: "+95", flag: "🇲🇲", placeholder: "9 212 3456" },
  NA: { name: "Namibia", code: "+264", flag: "🇳🇦", placeholder: "81 123 4567" },
  NR: { name: "Nauru", code: "+674", flag: "🇳🇷", placeholder: "555 1234" },
  NP: { name: "Nepal", code: "+977", flag: "🇳🇵", placeholder: "981-1234567" },
  NL: {
    name: "Netherlands",
    code: "+31",
    flag: "🇳🇱",
    placeholder: "6 12345678",
  },
  NC: {
    name: "New Caledonia",
    code: "+687",
    flag: "🇳🇨",
    placeholder: "75.12.34",
  },
  NZ: {
    name: "New Zealand",
    code: "+64",
    flag: "🇳🇿",
    placeholder: "21 123 4567",
  },
  NI: { name: "Nicaragua", code: "+505", flag: "🇳🇮", placeholder: "8123 4567" },
  NE: { name: "Niger", code: "+227", flag: "🇳🇪", placeholder: "93 12 34 56" },
  NG: {
    name: "Nigeria",
    code: "+234",
    flag: "🇳🇬",
    placeholder: "802 123 4567",
  },
  NU: { name: "Niue", code: "+683", flag: "🇳🇺", placeholder: "888 4012" },
  NF: {
    name: "Norfolk Island",
    code: "+672",
    flag: "🇳🇫",
    placeholder: "3 81234",
  },
  KP: {
    name: "North Korea",
    code: "+850",
    flag: "🇰🇵",
    placeholder: "191 2345",
  },
  MP: {
    name: "Northern Mariana Islands",
    code: "+1",
    flag: "🇲🇵",
    placeholder: "234 5678",
  },
  NO: { name: "Norway", code: "+47", flag: "🇳🇴", placeholder: "412 34 567" },
  OM: { name: "Oman", code: "+968", flag: "🇴🇲", placeholder: "9212 3456" },
  PK: { name: "Pakistan", code: "+92", flag: "🇵🇰", placeholder: "301 2345678" },
  PW: { name: "Palau", code: "+680", flag: "🇵🇼", placeholder: "620 1234" },
  PS: {
    name: "Palestinian Territory",
    code: "+970",
    flag: "🇵🇸",
    placeholder: "59 123 4567",
  },
  PA: { name: "Panama", code: "+507", flag: "🇵🇦", placeholder: "6001 2345" },
  PG: {
    name: "Papua New Guinea",
    code: "+675",
    flag: "🇵🇬",
    placeholder: "681 2345",
  },
  PY: { name: "Paraguay", code: "+595", flag: "🇵🇾", placeholder: "961 456789" },
  PE: { name: "Peru", code: "+51", flag: "🇵🇪", placeholder: "912 345 678" },
  PH: {
    name: "Philippines",
    code: "+63",
    flag: "🇵🇭",
    placeholder: "905 123 4567",
  },
  PL: { name: "Poland", code: "+48", flag: "🇵🇱", placeholder: "512 345 678" },
  PT: {
    name: "Portugal",
    code: "+351",
    flag: "🇵🇹",
    placeholder: "912 345 678",
  },
  PR: { name: "Puerto Rico", code: "+1", flag: "🇵🇷", placeholder: "234 5678" },
  QA: { name: "Qatar", code: "+974", flag: "🇶🇦", placeholder: "3312 3456" },
  RE: {
    name: "Reunion",
    code: "+262",
    flag: "🇷🇪",
    placeholder: "692 12 34 56",
  },
  RO: { name: "Romania", code: "+40", flag: "🇷🇴", placeholder: "712 345 678" },
  RU: { name: "Russia", code: "+7", flag: "🇷🇺", placeholder: "912 345 67 89" },
  RW: { name: "Rwanda", code: "+250", flag: "🇷🇼", placeholder: "728 123 456" },
  BL: {
    name: "Saint Barthelemy",
    code: "+590",
    flag: "🇧🇱",
    placeholder: "690 123 456",
  },
  SH: { name: "Saint Helena", code: "+290", flag: "🇸🇭", placeholder: "51234" },
  KN: {
    name: "Saint Kitts and Nevis",
    code: "+1",
    flag: "🇰🇳",
    placeholder: "765 1234",
  },
  LC: { name: "Saint Lucia", code: "+1", flag: "🇱🇨", placeholder: "284 5678" },
  MF: {
    name: "Saint Martin",
    code: "+590",
    flag: "🇲🇫",
    placeholder: "690 12 34 56",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    code: "+508",
    flag: "🇵🇲",
    placeholder: "55 12 34",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    code: "+1",
    flag: "🇻🇨",
    placeholder: "430 1234",
  },
  WS: { name: "Samoa", code: "+685", flag: "🇼🇸", placeholder: "72 12345" },
  SM: { name: "San Marino", code: "+378", flag: "🇸🇲", placeholder: "666 6666" },
  ST: {
    name: "Sao Tome and Principe",
    code: "+239",
    flag: "🇸🇹",
    placeholder: "981 1234",
  },
  SA: {
    name: "Saudi Arabia",
    code: "+966",
    flag: "🇸🇦",
    placeholder: "512 345 678",
  },
  SN: {
    name: "Senegal",
    code: "+221",
    flag: "🇸🇳",
    placeholder: "70 123 45 67",
  },
  RS: { name: "Serbia", code: "+381", flag: "🇷🇸", placeholder: "60 1234567" },
  SC: {
    name: "Seychelles",
    code: "+248",
    flag: "🇸🇨",
    placeholder: "2 510 123",
  },
  SL: {
    name: "Sierra Leone",
    code: "+232",
    flag: "🇸🇱",
    placeholder: "25 123456",
  },
  SG: { name: "Singapore", code: "+65", flag: "🇸🇬", placeholder: "8123 4567" },
  SX: { name: "Sint Maarten", code: "+1", flag: "🇸🇽", placeholder: "520 5678" },
  SK: {
    name: "Slovakia",
    code: "+421",
    flag: "🇸🇰",
    placeholder: "912 345 678",
  },
  SI: { name: "Slovenia", code: "+386", flag: "🇸🇮", placeholder: "31 234 567" },
  SB: {
    name: "Solomon Islands",
    code: "+677",
    flag: "🇸🇧",
    placeholder: "74 12345",
  },
  SO: { name: "Somalia", code: "+252", flag: "🇸🇴", placeholder: "7 1234567" },
  ZA: {
    name: "South Africa",
    code: "+27",
    flag: "🇿🇦",
    placeholder: "71 123 4567",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    code: "",
    flag: "🇬🇸",
    placeholder: "",
  },
  SS: {
    name: "South Sudan",
    code: "+211",
    flag: "🇸🇸",
    placeholder: "91 123 4567",
  },
  ES: { name: "Spain", code: "+34", flag: "🇪🇸", placeholder: "612 34 56 78" },
  LK: {
    name: "Sri Lanka",
    code: "+94",
    flag: "🇱🇰",
    placeholder: "71 234 5678",
  },
  SD: { name: "Sudan", code: "+249", flag: "🇸🇩", placeholder: "91 123 4567" },
  SR: { name: "Suriname", code: "+597", flag: "🇸🇷", placeholder: "741 2345" },
  SJ: {
    name: "Svalbard and Jan Mayen",
    code: "+47",
    flag: "🇸🇯",
    placeholder: "412 34 567",
  },
  SE: { name: "Sweden", code: "+46", flag: "🇸🇪", placeholder: "70 123 45 67" },
  CH: {
    name: "Switzerland",
    code: "+41",
    flag: "🇨🇭",
    placeholder: "78 123 45 67",
  },
  SY: { name: "Syria", code: "+963", flag: "🇸🇾", placeholder: "944 567 890" },
  TW: { name: "Taiwan", code: "+886", flag: "🇹🇼", placeholder: "912 345 678" },
  TJ: {
    name: "Tajikistan",
    code: "+992",
    flag: "🇹🇯",
    placeholder: "917 12 3456",
  },
  TZ: {
    name: "Tanzania",
    code: "+255",
    flag: "🇹🇿",
    placeholder: "621 123 456",
  },
  TH: { name: "Thailand", code: "+66", flag: "🇹🇭", placeholder: "81 234 5678" },
  TL: {
    name: "East Timor",
    code: "+670",
    flag: "🇹🇱",
    placeholder: "7721 2345",
  },
  TG: { name: "Togo", code: "+228", flag: "🇹🇬", placeholder: "90 11 23 45" },
  TK: { name: "Tokelau", code: "+690", flag: "🇹🇰", placeholder: "7290" },
  TO: { name: "Tonga", code: "+676", flag: "🇹🇴", placeholder: "771 5123" },
  TT: {
    name: "Trinidad and Tobago",
    code: "+1",
    flag: "🇹🇹",
    placeholder: "620 1234",
  },
  TN: { name: "Tunisia", code: "+216", flag: "🇹🇳", placeholder: "20 123 456" },
  TR: { name: "Turkey", code: "+90", flag: "🇹🇷", placeholder: "532 123 4567" },
  TM: {
    name: "Turkmenistan",
    code: "+993",
    flag: "🇹🇲",
    placeholder: "65 123456",
  },
  TC: {
    name: "Turks and Caicos Islands",
    code: "+1-649",
    flag: "🇹🇨",
    placeholder: "231 1234",
  },
  TV: { name: "Tuvalu", code: "+688", flag: "🇹🇻", placeholder: "901234" },
  UG: { name: "Uganda", code: "+256", flag: "🇺🇬", placeholder: "712 345678" },
  UA: { name: "Ukraine", code: "+380", flag: "🇺🇦", placeholder: "50 123 4567" },
  AE: {
    name: "United Arab Emirates",
    code: "+971",
    flag: "🇦🇪",
    placeholder: "50 123 4567",
  },
  GB: {
    name: "United Kingdom",
    code: "+44",
    flag: "🇬🇧",
    placeholder: "7400 123456",
  },
  US: {
    name: "United States",
    code: "+1",
    flag: "🇺🇸",
    placeholder: "201 555 0123",
  },
  UY: { name: "Uruguay", code: "+598", flag: "🇺🇾", placeholder: "9423 1234" },
  UZ: {
    name: "Uzbekistan",
    code: "+998",
    flag: "🇺🇿",
    placeholder: "90 123 45 67",
  },
  VU: { name: "Vanuatu", code: "+678", flag: "🇻🇺", placeholder: "591 2345" },
  VA: { name: "Vatican", code: "+379", flag: "🇻🇦", placeholder: "1234567" },
  VE: {
    name: "Venezuela",
    code: "+58",
    flag: "🇻🇪",
    placeholder: "412 123 4567",
  },
  VN: { name: "Vietnam", code: "+84", flag: "🇻🇳", placeholder: "91 234 56 78" },
  WF: {
    name: "Wallis and Futuna",
    code: "+681",
    flag: "🇼🇫",
    placeholder: "72 1234",
  },
  EH: {
    name: "Western Sahara",
    code: "+212",
    flag: "🇪🇭",
    placeholder: "528 12 34 56",
  },
  YE: { name: "Yemen", code: "+967", flag: "🇾🇪", placeholder: "712 345 678" },
  ZM: { name: "Zambia", code: "+260", flag: "🇿🇲", placeholder: "95 1234567" },
  ZW: {
    name: "Zimbabwe",
    code: "+263",
    flag: "🇿🇼",
    placeholder: "71 234 5678",
  },
};

export default countries;
